import { navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import {
    TextInput,
    PasswordInput,
    Anchor,
    Paper,
    useMantineTheme,
    Title,
    Text,
    Container,
    Group,
    Button,
    Alert,
    Switch,
    Center,
    LoadingOverlay,
    createStyles,
    Box,
 } from '@mantine/core';
import { motion, AnimatePresence, LayoutGroup } from "framer-motion";
import { Lock, Mail, Login, CircleCheck, ArrowLeft } from 'tabler-icons-react';
import { useTranslation } from 'react-i18next';
import React, { memo, useContext, createContext, useEffect, useState } from 'react';
// import Screenshots from '../components/landing/Screenshots';
import Wrapper from './../../components/shared/Wrapper';
import ModalContext from './../../contexts/ModalContext';
import UserContext from './../../contexts/UserContext';
import { AuthorizerMagicLinkLogin, useAuthorizer } from '@authorizerdev/authorizer-react';

const Home = () => {
    const { t } = useTranslation();

    const { emitter, events } = useContext( ModalContext );
//    const { user, loading, loginWithEmail, createUserJWT } = useContext( UserContext );
    const  [emailInput, setEmailInput]  = useState('');
    const  [passwordInput, setPasswordInput]  = useState('');
    const  [sendingLogin, setSendingLogin]  = useState( false );
    const  [userIDs, setUserIDs]  = useState( {email: ""} );
    const [ animateStage, setAnimateStage ] = useState("show");
    
    const [ emailStage, setEmailStage ] = useState( "hidden" );

    const theme = useMantineTheme();
    theme.colorScheme = 'dark';

    let showInitial = false;
    const [ passwordWrong, setPasswordWrong ] = useState( "" );
    const [ sentResetEmail, setSentResetEmail ] = useState( false );
    const [ resetPasswordLoading, setResetPasswordLoading ] = useState( false );

    const endpoint = "https://dashboard.boardsi.com";

    const {user, loading, token} = useAuthorizer();

    const useStyles = createStyles( ( theme ) => ({
        wrapper: {
            minHeight: "100vh",
            backgroundSize: 'cover',
        },

        form: {
            borderRight: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[ 7 ] : theme.colors.gray[ 3 ]
                }`,
            minHeight: "100vh",
            maxWidth: 500,
            paddingTop: 80,

            [ `@media (max-width: ${theme.breakpoints.sm}px)` ]: {
                maxWidth: '100%',
            },
        },

        title: {
            color: theme.colorScheme === 'dark' ? theme.white : theme.black,
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        },

        logo: {
            color: theme.colorScheme === 'dark' ? theme.white : theme.black,
            width: 120,
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        button: {
            backgroundColor: theme.colors.blue[ 5 ],
            '&:hover': {
                backgroundColor: theme.colors.blue[ 7 ],
            },
        },
        '&:hover': {
            backgroundColor: theme.colors.blue[ 7 ],
        },
        controls: {
            [ theme.fn.smallerThan( 'xs' ) ]: {
                flexDirection: 'column-reverse',
            },
        },

        control: {
            [ theme.fn.smallerThan( 'xs' ) ]: {
                width: '100%',
                textAlign: 'center',
            },
        },
    }));

    const container = {
        hidden: { opacity: 1, scale: 1 },
        show: {
            opacity: 1,
            scale: 1,
            transition: {
                delayChildren: 1,
                staggerChildren: 1,
            }
        }
    };

    const item = {
        hidden: i => ({ y: 40, opacity: 0 }),
        show: i => ( {
            y: 0,
            opacity: 1,
            transition: {
                delay: i * .25,
                duration: .18,
                type: "spring",
                bounce: 1,
                stiffness: 180,
                damping: 11,
            }
        } )
    };

    useEffect(()=>{
        if(user !== null) {
            if(token !== null) {
                localStorage.setItem('but', token.access_token);
                console.log(user);
                navigate('/app/dashboard');
            }
        }
    }, [loading, user])

    const { classes } = useStyles();



    const loginWithEmailWrapper = () => {
    //    logtoClient.signIn('http://localhost:8000/login/callback');
        /*
        setSendingLogin(true);
        loginWithEmail( emailInput, passwordInput ).then((results)=>{ // get session with email + password
            const { valid, email, pid, error } = results;
            if ( valid && typeof email == "string" && email.includes('@')) {
                setUserIDs( { email: email } );
                setTimeout( () => {
                    setSendingLogin( false );
                    setTimeout( navigate( '/app/dashboard' ), 200 );
                }, 3000 );
            } else {
                console.log(results);
                setSendingLogin( false );
                setPasswordWrong("Your password was incorrect");
            }
        });
        */
    }

    const resetPasswordFunction = ( event ) => {
        event.preventDefault(); 
        setResetPasswordLoading( true ); 
        setTimeout( () => { 
            setResetPasswordLoading( false ); 
            setSentResetEmail(true); 
        }, 1000, setResetPasswordLoading, resetPasswordLoading, setSentResetEmail, sentResetEmail ); 
    }

    

    return (
        <>
        <Wrapper>
            <Helmet>
                <link rel="canonical" href="https://document.boardsi.com/" />
            </Helmet>
            <motion.div layout initial="hidden" animate={animateStage} variants={container}>
                <Container size={420} my={80}>
                    <motion.div custom={1} className="" layout initial="hidden" animate={animateStage} variants={item} >
                        <img style={{ display: ( emailStage == "hidden" ? 'block' : 'none') }} className="w-auto px-6 py-0" src="https://boardsi.com/wp-content/uploads/2021/03/boardsi-logo-flat-1200-1-1568x350.png" />
                    </motion.div>

                    <motion.div custom={2} layout initial="hidden" animate={animateStage} variants={item} >
                        <Paper style={{ display: ( emailStage == "hidden" ? 'block' : 'none'), position: "relative" }} withBorder p={"xl"} mt={30} mb={10} radius="md">
                        <AuthorizerMagicLinkLogin onMagicLinkLogin={(response) => {console.log("oranges");console.log(response)}} />
                            {/*
                            <LoadingOverlay visible={sendingLogin} overlayOpacity={0.65} loaderProps={{ size: 'lg', variant: 'bars' }} />
                            <TextInput type="email" pt="xs" pb="xs" value={emailInput} onChange={( event ) => setEmailInput( event.currentTarget.value )} placeholder="john.doe@email.com" required />
                            <PasswordInput pb="xs" error={passwordWrong} id="input-password" value={passwordInput} onChange={( event ) => { setPasswordInput( event.currentTarget.value ); }} placeholder="Password" required mt="md" />
                            <Group position="apart" mt="md" className={classes.controls}>
                                <Switch label="Remember me" />
                                <Anchor onClick={( event ) => { event.preventDefault(); setAnimateStage( "hidden" ); setTimeout(()=>{setEmailStage( "show" );}, 300); }} href="#" size="sm">
                                    Forgot password?
                                </Anchor>
                            </Group>

                            /*userIDs.email == "" ? ( <Button p="xs" leftIcon={<Login size={18} />} type="boardsi" styles={{ backgroundColor: theme.colors.blue[ 5 ] }} className={classes.button} variant="filled" onClick={loginWithEmailWrapper} fullWidth>
                                Sign in
                            </Button> ) : ( <Button p="xs" leftIcon={<CircleCheck size={18} />} type="boardsi" styles={{ backgroundColor: theme.colors.green[ 5 ] }} className={classes.button} variant="filled" fullWidth mt="xl">
                                Success!
                            </Button> )}
                                */}
                        </Paper>
                    </motion.div>

                    <motion.div custom={1} layout initial="hidden" animate={emailStage} variants={item} >
                            <Title className={classes.title} align="center">
                                Forgot your password?
                            </Title>
                            <Text color="dimmed" size="sm" align="center">
                                Enter your email to get a reset link
                            </Text>
                        </motion.div>
                        <motion.div custom={2} layout initial="hidden" animate={emailStage} variants={item} >
                        <Paper withBorder shadow="md" p={30} mb="xl" radius="md" mt="xl">
                            <TextInput variant="default" placeholder="your.email@boardsi.com" required />
                            <Group position="apart" mt="lg" className={classes.controls}>
                                <Anchor onClick={( event ) => { event.preventDefault(); setEmailStage( "hidden" ); setSentResetEmail(false); setTimeout( () => { setAnimateStage( "show" ); }, 100 ); }} color="dimmed" size="sm" className={classes.control}>
                                    <Center inline>
                                        <ArrowLeft size={12} />
                                        <Box ml={5}>Back to login page</Box>
                                    </Center>
                                </Anchor>
                                {resetPasswordLoading == false ? 
                                ( <Button type="boardsi" variant="filled" onClick={resetPasswordFunction} className={classes.control}>Reset password</Button> ) : ( <Button type="boardsi" variant="filled" loading className={classes.control} styles={{ backgroundColor: theme.colors.blue[ 5 ] }}>Sending</Button> )
                                }
                            </Group>
                        </Paper>
                        </motion.div>

                        
                    {/*sentResetEmail ? ( <motion.div custom={1} layout initial="hidden" animate={emailStage} variants={item} ><Alert icon={<Mail size={16} />} title="Reset Email Sent!" color="green">
                            Go check the email you entered above, the reset email is valid for the next 10 minutes.
                    </Alert></motion.div> ) : (<></>) */}

                        
                </Container >
            </motion.div>
        </Wrapper>

       </>
    );
};

export default memo( Home );
 
